import React, { useState } from "react";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import firebase from "../firebaseConfig"; // Assuming you've initialized Firebase elsewhere
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import { auth } from "../firebaseConfig"; // Import auth from your firebaseConfig file
import firebase1 from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "./../styles/upload.css";
import { ToastContainer, toast } from "react-custom-alert";

const ImageUpload = () => {
  const [selectedFiles, setSelectedFiles] = useState({
    file1: null,
    file2: null,
    file3: null,
  });
  const alertSuccess = () => toast.success("success");
  const [file, setFile] = useState(null);
  const [link, setLink] = useState("");
  const [description, setDescription] = useState("");
  const [title,settitle]=useState("");
  const[heading1,setheading1]=useState("");
  const[heading2,setheading2]=useState("");
  const[paragraph1,setparagraph1]=useState("");
  const[paragraph2,setparagraph2]=useState("");

  const navigate = useNavigate(); // useNavigate hook for navigation

  const handleUpload = async () => {
    if (file) {
      const storage = getStorage(firebase); // 'firebase' should be your initialized Firebase app
      const storageRef = ref(storage, `DonationPage-Slider/${file.name}`);

      try {
        // Upload file to Firebase Storage
        await uploadBytes(storageRef, file);
        console.log("File uploaded successfully!");
        setFile(null); // Clear the file input after successful upload
        toast.success("Your images have been successfully submitted.");
        setSelectedFiles({
          file1: null,
          file2: null,
          file3: null,
        });
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      console.error("No file selected");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const firestore = firebase1.firestore();
      const storage = firebase1.storage();
      if (!file) {
        console.error("Please select an image.");
        return;
      }
      const storageRef = storage.ref(`images/${file.name}`);
      const uploadTask = storageRef.put(file);

      const snapshot = await uploadTask;
      const downloadURL = await snapshot.ref.getDownloadURL();

      const timestamp = firebase1.firestore.FieldValue.serverTimestamp();
      const submissionData = {
        imageUrl: downloadURL,
        link: link,
        description: description,
        submittedAt: timestamp,
      };
      await firestore.collection("NewsData").add(submissionData);

      setFile(null);
      setLink("");
      setDescription("");
      toast.success("Your images have been successfully submitted.");
      setSelectedFiles({
        file1: null,
        file2: null,
        file3: null,
      });
    } catch (error) {
      toast.error("Error uploading Image");
      console.error("Error submitting form data:", error);
    }
  };

  const handleSubmitBlogsection = async (e) => {
    e.preventDefault();
    try {
      const datetoday = new Date();
      const firestore = firebase1.firestore();
      const storage = firebase1.storage();
      if (!file) {
        console.error('Please select an image.');
        return;
      }
      const storageRef = storage.ref(`images/${file.name}`);
      const uploadTask = storageRef.put(file);

      const snapshot = await uploadTask;
      const downloadURL = await snapshot.ref.getDownloadURL();

      const timestamp = firebase1.firestore.FieldValue.serverTimestamp();
      const options = { year: 'numeric', month: 'long', day: 'numeric' };

      const submissionData = {
        imageUrl: downloadURL,
        title: title,
        heading1:heading1,
        paraghaph1:paragraph1,
        heading2:heading2,
        paragraph2:paragraph2,
        date: datetoday.toLocaleDateString(undefined, options),
        submittedAt: timestamp,
      };
      await firestore.collection('BlogData').add(submissionData);

      setFile(null);
      settitle('');
      setheading1('');
      setparagraph1('');
      setheading2('');
      setparagraph2('');
      toast.success("Blog Added Sucessfully");

    } catch (error) {
      toast.error("Error uploading Blog Data");
      console.error('Error submitting form data:', error);
    }
  };

  const handleFileChange = (event, fieldName) => {
    const file = event.target.files[0];

    if (event.target.files[0]) {
      setFile(event.target.files[0]);
    }

    if (file) {
      const reader = new FileReader();

      reader.onload = function (e) {
        setSelectedFiles((prevSelectedFiles) => ({
          ...prevSelectedFiles,
          [fieldName]: e.target.result,
        }));
      };

      reader.readAsDataURL(file);
    } else {
      setSelectedFiles((prevSelectedFiles) => ({
        ...prevSelectedFiles,
        [fieldName]: null,
      }));
    }
  };

  // const handleFileChange = (e) => {

  // };

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        // Sign-out successful. Redirect to login screen
        navigate("/login");
      })
      .catch((error) => {
        // An error occurred while signing out
        console.error("Error signing out:", error);
      });
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {/* This div wraps the logout button */}
        <div style={{ margin: "40px" }}>
          <button
            onClick={handleLogout}
            className="button-5"
            style={{ borderRadius: "5px" }}
          >
            Logout
          </button>
        </div>
      </div>

      {/* //Slider part  */}

      <div
        style={{
          position: "relative",
          top: "50%",
          left: "30%",
          width: "40vw",
          border: "1px solid black",
          borderRadius: "5px",
          padding: "20px",
          backgroundColor: "#f0f0f0", // Background color
          boxSizing: "border-box", // Set box-sizing to border-box
          marginBottom: "140px",
        }}
      >
        <h3>Upload the Image For the Donation Page Slider Section: </h3>
        <input type="file" onChange={(e) => handleFileChange(e, "file1")} />
        {selectedFiles.file1 && (
          <div>
            <img
              src={selectedFiles.file1}
              alt="Selected File 1"
              style={{ maxWidth: "100%" }}
            />
          </div>
        )}
        <button
          onClick={handleUpload}
          className="button-5"
          style={{ marginTop: "5px", borderRadius: "5px" }}
        >
          Upload
        </button>
        {/* Other elements for the UI */}
      </div>

      {/* //gallery */}

      <div
        style={{
          position: "relative",
          top: "50%",
          left: "30%",
          width: "40vw",
          border: "1px solid black",
          borderRadius: "5px",
          padding: "20px",
          backgroundColor: "#f0f0f0", // Background color
          boxSizing: "border-box", // Set box-sizing to border-box
          marginBottom: "140px",
        }}
      >
        <h3>Upload the Image For the Donation page Gallery Section: </h3>
        <input type="file" onChange={(e) => handleFileChange(e, "file2")} />
        {selectedFiles.file2 && (
          <div>
            <img
              src={selectedFiles.file2}
              alt="Selected File 2"
              style={{ maxWidth: "100%" }}
            />
          </div>
        )}
        <button
          onClick={handleUpload}
          className="button-5"
          style={{ marginTop: "5px", borderRadius: "5px" }}
        >
          Upload
        </button>
        {/* Other elements for the UI */}
      </div>

      {/* //fot the new section */}
      <div
        style={{
          position: "relative",
          top: "50%",
          left: "30%",
          width: "40vw",
          border: "1px solid black",
          borderRadius: "5px",
          padding: "20px",
          backgroundColor: "#f0f0f0", // Background color
          boxSizing: "border-box", // Set box-sizing to border-box
          marginBottom: "140px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h3>Upload the Image For the Donation News Section: </h3>
        <label htmlFor="imageInput">Upload Image:</label>
        {/* <input
          type="file"
          id="imageInput"
          accept="image/*"
          onChange={handleFileChange}
          required
        /> */}
        <input
          type="file"
          id="imageInput"
          accept="image/*"
          onChange={(e) => handleFileChange(e, "file3")}
        />
        {selectedFiles.file3 && (
          <div>
            <img
              src={selectedFiles.file3}
              alt="Selected File 3"
              style={{ maxWidth: "100%" }}
            />
          </div>
        )}

        <label htmlFor="linkInput">Image Link:</label>
        <input
          type="url"
          id="linkInput"
          placeholder="Enter image link"
          value={link}
          onChange={(e) => setLink(e.target.value)}
        />

        <label htmlFor="descriptionInput">Description:</label>
        <textarea
          id="descriptionInput"
          placeholder="Enter image description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
        <button
          onClick={handleSubmit}
          className="button-5"
          style={{ marginTop: "5px", borderRadius: "5px" }}
        >
          Upload
        </button>
        {/* Other elements for the UI */}
      </div>


{/* //fot the Blog section */}
<div  style={{ position: 'relative',
    top: '50%',
    left: '30%', width: "40vw" ,
    border: '1px solid black',
    borderRadius: "5px",
    padding: '20px',
    backgroundColor: '#f0f0f0', // Background color
    boxSizing: 'border-box', // Set box-sizing to border-box
    marginBottom: "140px",
    display:"flex",
    flexDirection:"column"
 }}>
  <h3>Blog Section: </h3>

       <label htmlFor="MainHeading">Main Heading</label>
        <input type="text" id="MainHeading" placeholder="Enter Main Heading" value={title}onChange={(e) => settitle(e.target.value)}/>

      <label htmlFor="imageInput">Upload Image:</label>
        <input type="file" id="imageInput" accept="image/*" onChange={handleFileChange} required />

      <label htmlFor="Heading1">Heading1</label>
        <input type="text" id="Heading1" placeholder="Enter New Heading 1" value={heading1} onChange={(e) => setheading1(e.target.value)}/>

      <label htmlFor="Paragraph1">Paragraph1</label>
        <textarea id="Paragraph1" placeholder="Enter Paragraph 1" value={paragraph1} onChange={(e) => setparagraph1(e.target.value)} />
      
      <label htmlFor="Heading2">Heading2</label>
        <input type="text" id="Heading2" placeholder="Enter New Heading 2" value={heading2} onChange={(e) => setheading2(e.target.value)}/>

      <label htmlFor="Paragraph2">Paragraph2</label>
        <textarea id="Paragraph2" placeholder="Enter Paragraph 2" value={paragraph2} onChange={(e) => setparagraph2(e.target.value)} />
      
      <button onClick={handleSubmitBlogsection} className="button-5" style={{marginTop: "5px", borderRadius: "5px",}}>Upload</button>
   
    </div>

    </>
  );
};

export default ImageUpload;
