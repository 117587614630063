// import React, { useState } from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import './../styles/VideoGallery.css';
// import v1 from '../videos/1.mp4';
// import v2 from '../videos/2.mp4';
// import v3 from '../videos/3.mp4';
// import v4 from '../videos/4.mp4';
// import v5 from '../videos/5.mp4';
// import v6 from '../videos/6.mp4';

// const VideoGallery = () => {
//   const [popupVideo, setPopupVideo] = useState(null);

//   const videos = [v1, v2, v3, v4, v5, v6];

//   const settings = {
//     dots: true,
//     fade: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//   };

//   const openPopupVideo = (videoSrc) => {
//     setPopupVideo(videoSrc);
//   };

//   const closePopupVideo = () => {
//     setPopupVideo(null);
//   };

//   return (
//     <div className="container">
//       <h1>Video gallery</h1>
//       <div className="video-container">
//         <Slider {...settings}>
//           {videos.map((videoSrc, index) => (
//             <div key={index} className="video" onClick={() => openPopupVideo(videoSrc)}>
//               <video src={videoSrc} muted></video>
//             </div>
//           ))}
//         </Slider>
//       </div>
//       {popupVideo && (
//         <div className="popup-video">
//           <span onClick={closePopupVideo}>&times;</span>
//           <video src={popupVideo} muted autoPlay controls></video>
//         </div>
//       )}
//     </div>
//   );
// };

// export default VideoGallery;

import React,{useState,useEffect} from "react";
import "./../styles/VideoGallery.css";
// import v1 from "../videos/1.mp4";
// import v2 from "../videos/2.mp4";
// import v3 from "../videos/3.mp4";
// import v4 from "../videos/4.mp4";
// import v5 from "../videos/5.mp4";
// import v6 from "../videos/6.mp4";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAgdH2mmvs0U-pd_9DuKAzXlayrjxu9Dr8",
  authDomain: "iskconpuriglowal.firebaseapp.com",
  projectId: "iskconpuriglowal",
  storageBucket: "iskconpuriglowal.appspot.com",
  messagingSenderId: "813384272467",
  appId: "1:813384272467:web:5070fa8f06aceb182d94a6",
  measurementId: "G-1S8GH5W9ZD",
};

const VideoScreen = () => {
  const [imageUrls, setImageUrls] = useState([]);
  console.log(imageUrls);
  useEffect(() => {
    // Initialize Firebase app if not already initialized
    const app = initializeApp(firebaseConfig);

    // Reference to the Firebase Storage folder
    const storage = getStorage(app);
    const storageRef = ref(storage, "DonationPage-Video");

    // Fetch all the images from the folder
    listAll(storageRef)
      .then((result) => {
        const urlsPromises = result.items.map((imageRef) =>
          getDownloadURL(imageRef)
        );
        return Promise.all(urlsPromises);
      })
      .then((urls) => {
        setImageUrls(urls);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);

  // const videos = [v1, v2, v3, v4, v5, v6];

  return (
    <>
      <div className="container">
        <h1>Video gallery</h1>
        <div className="video-container">
          {imageUrls.map((videoSrc, index) => (
            <div key={index} className="video">
              <video src={videoSrc} muted controls autoPlay></video>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default VideoScreen;
