import React from 'react'
import '../styles/timing.css';

export default function Timing() {
  return (
    <div className='timingdiv'>
      <h1>Temple Timings | Open All Days</h1>
      <p>Closed from 13:00 - 16:00 and 21:00 - 04.30 (Next day)</p>

      <div className="timingrow">
        <div className="timingcolumn">
            <div className="timingcolumndiv">
                <div className="timingcolumndivtime">
                    <h3>4:30</h3>
                </div>
                <div className="timingcolumndivdescription">
                    <h3>Mangala arati</h3>
                    <p>First worship of the day</p>
                </div>
            </div>

            <div className="timingcolumndiv">
                <div className="timingcolumndivtime">
                    <h3>5:00</h3>
                </div>
                <div className="timingcolumndivdescription">
                    <h3>Tulsi-Puja</h3>
                    <p>Honoring Tilasi Plant</p>
                </div>
            </div>

            <div className="timingcolumndiv">
                <div className="timingcolumndivtime">
                    <h3>7:15</h3>
                </div>
                <div className="timingcolumndivdescription">
                    <h3>Srinagar darshan</h3>
                    <p>Greeting the Deities in new outfit</p>
                </div>
            </div>

            <div className="timingcolumndiv">
                <div className="timingcolumndivtime">
                    <h3>7:25</h3>
                </div>
                <div className="timingcolumndivdescription">
                    <h3>Guru puja</h3>
                    <p>Honoring the Founder-acharya</p>
                </div>
            </div>

            <div className="timingcolumndiv">
                <div className="timingcolumndivtime">
                    <h3>8:00</h3>
                </div>
                <div className="timingcolumndivdescription">
                    <h3>Bhagavatam class</h3>
                    <p>Discourse from Vedic scripture</p>
                </div>
            </div>
        </div>

        <div className="timingcolumn">
            <div className="timingcolumndiv">
                <div className="timingcolumndivtime">
                    <h3>12:30</h3>
                </div>
                <div className="timingcolumndivdescription">
                    <h3>Raj Bhoga arati</h3>
                    <p>Post noon worship</p>
                </div>
            </div>

            <div className="timingcolumndiv">
                <div className="timingcolumndivtime">
                    <h3>16:15</h3>
                </div>
                <div className="timingcolumndivdescription">
                    <h3>Usthapana arati</h3>
                    <p>Early evening worship</p>
                </div>
            </div>

            <div className="timingcolumndiv">
                <div className="timingcolumndivtime">
                    <h3>19:00</h3>
                </div>
                <div className="timingcolumndivdescription">
                    <h3>Sandhya arati</h3>
                    <p>Evening worship ceremony</p>
                </div>
            </div>

            <div className="timingcolumndiv">
                <div className="timingcolumndivtime">
                    <h3>19:30</h3>
                </div>
                <div className="timingcolumndivdescription">
                    <h3>Bhagavad-gita discourage</h3>
                    <p>Honoring Tulasi plant</p>
                </div>
            </div>

            <div className="timingcolumndiv">
                <div className="timingcolumndivtime">
                    <h3>20:30</h3>
                </div>
                <div className="timingcolumndivdescription">
                    <h3>Shayana arati</h3>
                    <p>Last worship ceremony of the day</p>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}
