import React, { useEffect,useState} from 'react';
import '../styles/home.css';
import ImageSlider from './ImageSlider';
import Aos from 'aos';
import 'aos/dist/aos.css';

import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";

import { register } from "swiper/element/bundle";
register();


const firebaseConfig = {
  apiKey: "AIzaSyAgdH2mmvs0U-pd_9DuKAzXlayrjxu9Dr8",
  authDomain: "iskconpuriglowal.firebaseapp.com",
  projectId: "iskconpuriglowal",
  storageBucket: "iskconpuriglowal.appspot.com",
  messagingSenderId: "813384272467",
  appId: "1:813384272467:web:5070fa8f06aceb182d94a6",
  measurementId: "G-1S8GH5W9ZD",
};

export default function Home() {

  const [slides, setImageUrls] = useState([]);
  console.log(slides);
  useEffect(() => {
    // Initialize Firebase app if not already initialized
    const app = initializeApp(firebaseConfig);

    // Reference to the Firebase Storage folder
    const storage = getStorage(app);
    const storageRef = ref(storage, "DonationPage-Slider");

    // Fetch all the images from the folder
    listAll(storageRef)
      .then((result) => {
        const urlsPromises = result.items.map((imageRef) =>
          getDownloadURL(imageRef)
        );
        return Promise.all(urlsPromises);
      })
      .then((urls) => {
        setImageUrls(urls);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <div className='homeee'>
      <ImageSlider slides={slides}/>
      </div>
      <div className='homeDescription'>
        <h3 data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="300">
          We request you to Join our hands to support this initiative for keeping
          <br/>
          this distribution enduring.
        </h3>
      </div>
    </>
  )
}
