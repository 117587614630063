import React from 'react';
// import Donate from "../components/Donate";
import Home from "../components/Home";
import VideoGallery from '../components/VideoGallery.jsx';
// import FloatButton from "../components/FloatButton.jsx";
import News from "../components/News";
import SocialMediaGallery from "../components/SocialMediaGallery";
// import Header from "../components/Header";
import InfoIskcon from "../components/InfoIskcon";
import FoodForLifes from './Donation/FoodForLife.jsx';
import PopupMenu from '../components/PopupMenu.jsx';
// import FoodForLife from '../components/FoodForLife.jsx';
// import Foodforlife2 from '../components/Foodforlife2.jsx';
// import Content from '../components/Content.jsx';
// import GetNewsData from '../components/GetNewsData.jsx';

const HomeScreen = () => {
  return (
    <div className="App">
      {/* <Content/> */}
       <Home />
       {/* <GetNewsData/> */}
       <FoodForLifes />
       
      <News/> 
      <VideoGallery />
      <SocialMediaGallery />
      <InfoIskcon />
      <PopupMenu/>
      
      {/* <FloatButton /> */}
    </div>
  );
};

export default HomeScreen;
