import React from "react";
import Donation from "../../components/Donation";
import geeta1 from "../../images/geeta1.webp";
import geeta2 from "../../images/geeta2.webp";
import Header3 from "../../components/Header3";
const GitaDaan = [
  { title: "Donate 1 Gita", price: "₹250" },
  { title: "Donate 3 Gita", price: "₹750" },
  { title: "Donate 5 Gita", price: "₹1250" },
  { title: "Donate 8 Gita", price: "₹2000" },
  { title: "Donate 12 Gita", price: "₹3000" },
  { title: "Donate 20 Gita", price: "₹5000" },
  { title: "Donate 32 Gita", price: "₹8000" },
  { title: "Donate 64 Gita", price: "₹16000" },
];

const GitaDaamSeva = () => {
  return (
    <div>
      <Header3 />
      <Donation
        topheading="Gita Daan Seva"
        toppara="“For one who explains this supreme secret to the devotees, pure devotional service is guaranteed, and at the end he will come back to Me.”"
        para1="Saints distribute love and become a carrier of the Lord’s compassion. Let’s extend a serving hand to the saviors of society by distributing knowledge, Bhagavad Gita."
        para2="We live in a society where we see people struggling with anxiety, depression, and stress. They take stress-relieving pills and medicines which affect their subtle bodies. Drugs and the consumption of weed or alcohol are common among youths. The quality of human civilization is deteriorating day by day. Murders, rapes, smuggling and many other criminal activities are common nowadays. What is the solution to this? Only solution to this problem is the teachings of Bhagavad Gita."
        li1="Please do not miss this rare opportunity to get the blessing of Sri Sri Gour Nitai Ji by distributing this transcendental knowledge."
        li2="“If one reads Bhagavad-gita very sincerely and with all seriousness, then by the grace of the Lord the reactions of his past misdeeds will not act upon him.” (gita-mahatmya 2)"
        li3="The merit of pious activities that we have accumulated during life travels with us to the next life. Donate & Read Bhagavad Gita to experience the divine shower of blessing in your life."
        img1={geeta1}
        img2={geeta2}
      />
      <div>
        <div className="donaLower">
          <h1>You Can Also Contribute For Gita Daan Seva!!</h1>
          <div className="donationdona">
            {GitaDaan.map((i, key) => (
              <div
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="5000"
                className="donadona"
                key={key}
              >
                <div className="contentdon">
                  <h2>{i.title}</h2>
                  <p>{i.price}</p>
                </div>
                <button
                  className="btnDona"
                  onClick={() =>
                    (window.location.href = "https://razorpay.me/@iskcon2332")
                  }
                >
                  Donation
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GitaDaamSeva;
