import React from "react";
import Donation from "../../components/Donation";
import sadhu1 from "../../images/sadhu1.webp";
import sadhu2 from "../../images/sadhu2.png";
import Header3 from "../../components/Header3";
const SadhuBhojan = [
  { title: "Bhojan For 1 Sadhu", price: "₹100" },
  { title: "Bhojan For 5 Sadhu", price: "₹500" },
  { title: "Bhojan For 10 Sadhu", price: "₹1000" },
  { title: "Bhojan For 20 Sadhu", price: "₹2000" },
  { title: "Bhojan For 50 Sadhu", price: "₹5000" },
  { title: "1 Day Sadhu Bhojan", price: "₹11000" },
];
const SadhuBhojanSeva = () => {
  return (
    <div>
      <Header3 />
      <Donation
        topheading="Sadhu Bhojan Seva"
        toppara="“It is the duty of every living being to perform welfare activities for the benefit of others with his life, wealth, intelligence and words.”"
        para1="Saints distribute love and become a carrier of the Lord’s compassion. Let’s extend a serving hand to the saviors of society."
        para2="The tricolors of our National flag symbolize sacrifice with saffron, peace with white, and prosperity with Green color. Saffron indicates those people who sacrifice their lives to the upliftment of society and we tend to cultivate respect for them. Saints are those who voluntarily choose to live a life of celibacy for the higher purpose of spreading the encrypted wisdom of our Scriptures to society, for them whole world is their family (Vasudev Kutumbhkam). They pave the way for Dharma which leads to peace and prosperity in society, which makes them very dear to Krishna."
        li1="Please do not miss this rare opportunity to get the blessing of Sri Sri Gour Nitai Ji by serving the devotees of Lord."
        li2="From Ancient time we have had a culture of Charity, our scriptures tells us that we shouldn’t live a self-centered life. Feeding Sadhu is one of the pious activities that pleases Krishna and brings harmony, prosperity, and blessing to our family."
        li3="The merit of pious activities that we have accumulated during life travels with us to the next life. Donate to feed Sadhu and experience the divine shower of blessing in your life."
        img1={sadhu1}
        img2={sadhu2}
      />
      <div>
        <div className="donaLower">
          <h1>You Can Also Contribute For Sadhu Bhojan!!</h1>
          <div className="donationdona">
            {SadhuBhojan.map((i, key) => (
              <div
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="5000"
                className="donadona"
                key={key}
              >
                <div className="contentdon">
                  <h2>{i.title}</h2>
                  <p>{i.price}</p>
                </div>
                <button
                  className="btnDona"
                  onClick={() =>
                    (window.location.href = "https://razorpay.me/@iskcon2332")
                  }
                >
                  Donation
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SadhuBhojanSeva;
