import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
const firebaseConfig = {
  apiKey: "AIzaSyAgdH2mmvs0U-pd_9DuKAzXlayrjxu9Dr8",
  authDomain: "iskconpuriglowal.firebaseapp.com",
  projectId: "iskconpuriglowal",
  storageBucket: "iskconpuriglowal.appspot.com",
  messagingSenderId: "813384272467",
  appId: "1:813384272467:web:5070fa8f06aceb182d94a6",
  measurementId: "G-1S8GH5W9ZD"
};

// Initialize Firebase if it's not already initialized
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

// Get the Firebase Storage instance
const storage = getStorage(app);
export { db };
// Get the Firebase Authentication instance
const auth = getAuth(app);
// Set persistence to 'local' so that the user remains logged in across browser restarts
setPersistence(auth, browserLocalPersistence);

export { storage, auth}; // Export the 'storage' and 'auth', Firestore instance modules
export default app; // Export the Firebase app instance






