import React from "react";
import Hare from "./../../images/hare.jpg";
import "./../../styles/About.css";
const FAQ = () => {
  return (
    <section className="secoffaq">
      <div className="gopaljiheading">
        <h1 style={{ color: "white" }}>Ask questions ISKCON</h1>
      </div>
      <p>
        The International Society for Krishna Consciousness (ISKCON) is a
        spiritual institution founded by Bhaktivedanta Swami Prabhupada in July
        1966 in New York City of USA. The aim of forming this institution was to
        spread Bhakti Yoga in the world. Also popular by the name, Hare Krishna
        movement, the beliefs of ISKCON are based mainly on ancient scriptures,
        the Bhagwad –Purana or Srimad Bhagavatam and the Bhagavad-gita.
      </p>
      <p>
        From the day of its foundation, ISKCON has witnessed a tremendous growth
        worldwide both in its infrastructure and membership. It is also growing
        fast in the eastern countries. Devotees all across the world ask
        questions ISKCON to clear their confusions and doubts and to get new
        knowledge about faith. You too can ask questions ISKCON related to
        faith, Lord Krishna, and temples etc. ISKCON will answer your questions
        in a minimum possible time.
      </p>
      <div className="guideline">
        <h1>ASK QUESTIONS ISKCON GUIDELINES</h1>
        <p>
          Your questions are a valuable asset to ISKCON. We receive thousands of
          your questions everyday and we try to answer all of them. But there
          are some guidelines that you should follow when you ask questions
          ISKCON. These guidelines are necessary if you want answers to your
          questions in the least possible time.
        </p>
        <div className="guidelin1">
          <p>
            <b>Don’t repeat the same question</b>
          </p>
          <p>
            Before you ask questions ISKCON, please check that it has not been
            already asked by some other devotee. For that check our website and
            enter appropriate keywords in the search field.
          </p>
        </div>
        <div className="guidelin1">
          <p>
            <b>Keep questions precise and clear</b>
          </p>
          <p>
            Always try to keep your question precise and clear. When you ask
            questions ISKCON in a neat and precise way, it becomes easy to
            answer the questions.
          </p>
        </div>
        <div className="guidelin1">
          <p>
            <b>Write grammatically correct language</b>
          </p>
          <p>
            Try to ensure that you write grammatically correct language. It
            becomes easy to understand a question which is grammatically
            correct.
          </p>
        </div>
        <img data-aos="zoom-in-left" src={Hare} alt="" />
        <div className="guidelin1">
          <p>
            <b>Ask questions with name or anonymously</b>
          </p>
          <p>
            You can ask questions ISKCON anonymously or with your name. You have
            both the options available. You have to state whether your question
            be answered without mentioning your name or with name.
          </p>
        </div>
        <div className="guidelin1">
          <p>
            <b>Don’t ask personal questions</b>
          </p>
          <p>
            Iskcon intends to answer all questions as soon as possible except
            personal questions. Your personal questions will not be answered at
            any cost.
          </p>
        </div>
      </div>
      <div className="faqq">
        <h1>Some frequently asked questions (FAQ)</h1>
        <div className="guidelin1">
          <p>
            <b>How can one become a Life patron member of ISKCON?</b>
          </p>
          <p>
            Just submit the application form along with donation and three color
            stamp sized photos. And your card will arrive within few days.
          </p>
        </div>
        <div className="guidelin1">
          <p>
            <b>What is the donation amount for ISKCON Life patron ship?</b>
          </p>
          <p>
            The donation amount is Rs. 35,555 for people who opt for life patron
            membership.
          </p>
        </div>
        <div className="guidelin1">
          <p>
            <b>What is the eligibility to receive ISKCON Privileges?</b>
          </p>
          <p>
            Once ISKCON receives your payment and it is credited to the ISKCON
            bank account, you will receive gift pack at your address. And once
            you get patron membership card, you will become eligible for
            prescribed accommodation facility.
          </p>
        </div>

        <div className="guidelin1">
          <p>
            <b>How to make a donation?</b>
          </p>
          <p>
            You can donate via demands draft, cash, credit card, cheque or bank
            transfer. But cheques and bank transfer should be in the name of
            ISKCON.
          </p>
        </div>
        <div className="guidelin1">
          <p>
            <b>Is it possible to transfer patron membership card to others?</b>
          </p>
          <p>No.</p>
        </div>
        <div className="guidelin1">
          <p>
            <b>
              If I have to stay at ISKCON Center, what is the procedure for it?
            </b>
          </p>
          <p>
            Before you ask questions ISKCON, please check that it has not been
            already asked by some other devotee. For that check our website and
            enter appropriate keywords in the search field.
          </p>
        </div>
        <p>You can contact and ask questions Iskcon for further guidance.</p>
      </div>
    </section>
  );
};

export default FAQ;
