import React,{useEffect} from "react";
import Aos from 'aos';
import "aos/dist/aos.css"
import "../styles/donate.css";
import {Link} from 'react-router-dom';
import { Button } from 'react-bootstrap';

const Donate = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  
  return (
    <>
    <section className="donationSection">
      <div className="DonationHeading" data-aos="fade-down" data-aos-easing="ease-in-sine" data-aos-delay="300">
        <h1>Donate to ISKCON to Feed Poor</h1>
      </div>

      <div className="donationRow">
        <div className="donationColumn">

          <div className="donationCardRow">
              <div className="donationCardColumn">
                
                 <div className="new-block-custom" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="300">
                     <h4 className="sec-head">Donate Any Amount</h4><br />
                     <h3 className="sec-head">
                     <span>₹ Your Wish</span>
                     </h3>
                     <br />
                     <Button as={Link} to="/checkout" style={{backgroundColor:"#fd854a",border:"none"}} className="btn-block">Donate Now</Button>
                </div>
              </div>

              {/* <div className="donationCardColumn">
                     <div className="new-block-custom" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="300">
                        <h4 className="sec-head">Donate 1000 Meals</h4><br />
                        <h3 className="sec-head">
                        <span>₹ 75000</span>
                        </h3>
                        <br />
                        <Button as={Link} to="/checkout" style={{backgroundColor:"#fd854a",border:"none"}} className="btn-block">Donate Now</Button>
                      </div>
                </div> */}

          </div> 
          
          {/* <div className="donationCardRow">
              
                <div className="donationCardColumn">
                  <div className="new-block-custom" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="300">
                    <h4 className="sec-head">Donate 2500 Meals</h4><br />
                    <h3 className="sec-head">
                    <span>₹ 187500</span>
                    </h3>
                    <br />
                    <Button as={Link} to="/checkout" style={{backgroundColor:"#fd854a",border:"none"}} className="btn-block">Donate Now</Button>
                  </div>
              </div>

                <div className="donationCardColumn">
                    <div className="new-block-custom" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="300">
                        <h4 className="sec-head">Donate 5000 Meals</h4><br />
                        <h3 className="sec-head">
                        <span>₹ 375000</span>
                        </h3>
                        <br />
                        <Button as={Link} to="/checkout" style={{backgroundColor:"#fd854a",border:"none"}} className="btn-block">Donate Now</Button>
                    </div>
                </div>
          </div> */}

          

              <table className="table" data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-delay="300">
            <tbody>
              <tr>
                <td valign="middle" className="tableData1" >
                  <h4 class="sec-head">Donate 25 MEALS</h4>
                </td>
                <td valign="middle">
                  <h3 class="sec-head1">
                    <span>₹ 1875</span>
                  </h3>
                </td>
                <td
                  valign="middle"
                  style={{ "text-align": "center" }}
                  class="yellow-border"
                >
                  <Button as={Link} to="/checkout" style={{backgroundColor:"black",border:"none",fontSize:"15px"}} className="btn-block">Donate Now</Button>
                </td>
              </tr>
              <tr>
                <td valign="middle" className="tableData1">
                  <h4 class="sec-head">Donate 50 MEALS</h4>
                </td>
                <td valign="middle">
                  <h3 class="sec-head1">
                    <span>₹ 3750</span>
                  </h3>
                </td>
                <td
                  valign="middle"
                  style={{ "text-align": "center" }}
                  class="yellow-border"
                >
                 <Button as={Link} to="/checkout" style={{backgroundColor:"black",border:"none",fontSize:"15px"}} className="btn-block">Donate Now</Button>
                </td>
              </tr>
              <tr>
                <td valign="middle" className="tableData1">
                  <h4 class="sec-head">Donate 100 MEALS</h4>
                </td>
                <td valign="middle">
                  <h3 class="sec-head1">
                    <span>₹ 7500</span>
                  </h3>
                </td>
                <td
                  valign="middle"
                  style={{ "text-align": "center" }}
                  class="yellow-border"
                >
             <Button as={Link} to="/checkout" style={{backgroundColor:"black",border:"none",fontSize:"15px"}} className="btn-block">Donate Now</Button>
                </td>
              </tr>
              <tr>
                <td valign="middle" className="tableData1">
                  <h4 class="sec-head">Donate 250 MEALS</h4>
                </td>
                <td valign="middle">
                  <h3 class="sec-head1">
                    <span>₹ 18750</span>
                  </h3>
                </td>
                <td
                  valign="middle"
                  style={{ "text-align": "center" }}
                  class="yellow-border"
                >
              <Button as={Link} to="/checkout" style={{backgroundColor:"black",border:"none",fontSize:"15px"}} className="btn-block">Donate Now</Button>
                </td>
              </tr>
              <tr>
                <td valign="middle" className="tableData1">
                  <h4 class="sec-head">Donate 500 MEALS</h4>
                </td>
                <td valign="middle">
                  <h3 class="sec-head1">
                    <span>₹ 37500</span>
                  </h3>
                </td>
                <td
                  valign="middle"
                  style={{ "text-align": "center" }}
                  class="yellow-border"
                >
                  <Button as={Link} to="/checkout" style={{backgroundColor:"black",border:"none",fontSize:"15px"}} className="btn-block">Donate Now</Button>
                </td>
              </tr>
            </tbody>
          </table>
              
        </div>

        <div className="donationColumn" data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-delay="300">
        <div className="footer-content">
                <div className="footer-head">
                  <div className="footer-logo donationbank">
                    <h5>
                      <b>Donation Through Bank (NEFT/ RTGS)</b>
                    </h5>
                  </div>
                  <div className="inforofbank">
                    <p>
                      Bank Name - HDFC BANK
                    </p>
                  </div>
                  <div className="inforofbank">
                    <p>
                      Account Name -{" "} INTERNATIONAL SOCIETY FOR KRISHNA CONSCIOUSNESS
                      <button className="copybutton" style={{ justifyContent: "end" }}>copy</button>
                    </p>
                    
                  </div>
                  <div className="inforofbank">
                    <p>
                      Account Number – 50100533221349
                      <button className="copybutton"  style={{ justifyContent: "end" }}>copy</button>
                    </p>
                    
                  </div>
                  <div className="inforofbank ">
                    <p>
                      IFSC Code - HDFC0001117
                      <button className="copybutton"  style={{ justifyContent: "end" }}>copy</button>
                    </p>
                  </div>
                  <div className="inforofbank">
                  <p>
                    Branch - VIP ROAD Branch, Puri
                    <button>copy</button>
                  </p>
                 </div>
                  <div className="orange">
                    <p>
                    80G Available As Per Income Tax Act 1961 And Rules Made Thereunder
                    </p>
                  </div>
                  <div className="income">
                    <p>Income Tax Exemption (80-G) Number AAATI0017PF20219</p>
                  </div>
                  <div className="orange">
                    <p>
                     80G Benefits Cannot Be Availed On Paytm Donations Except Paytm UPI Transfer
                    </p>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </section>
    {/* <div className="black-bg-text">
    <div className="instructionDiv">
      <h3 data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="300">
      ISKCON Jagannath Puri is India's 2nd Biggest ISKCON Temple.<br/>
      The project will be spread across 22 Acres, with 2,00,000 Sq. ft. of construction which will be built in the Kalinga Architecture style.
      </h3>
    </div>
  </div> */}
  </>
  );
};

export default Donate;

