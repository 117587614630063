import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css"; // Import slick carousel CSS
import "slick-carousel/slick/slick-theme.css"; // Import slick carousel theme CSS
import Slider from "react-slick"; // Import Slider from react-slick
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";
import '../styles/VideoGallery.css';

const firebaseConfig = {
  apiKey: "AIzaSyAgdH2mmvs0U-pd_9DuKAzXlayrjxu9Dr8",
  authDomain: "iskconpuriglowal.firebaseapp.com",
  projectId: "iskconpuriglowal",
  storageBucket: "iskconpuriglowal.appspot.com",
  messagingSenderId: "813384272467",
  appId: "1:813384272467:web:5070fa8f06aceb182d94a6",
  measurementId: "G-1S8GH5W9ZD",
};

const VideoGallery = () => {
  const [imageUrls, setImageUrls] = useState([]);
  
  // Initialize Firebase app outside the useEffect
  const app = initializeApp(firebaseConfig);

  useEffect(() => {
    // Reference to the Firebase Storage folder
    const storage = getStorage(app);
    const storageRef = ref(storage, "DonationPage-Video");

    // Fetch all the videos from the folder
    listAll(storageRef)
      .then((result) => {
        const urlsPromises = result.items.map((videoRef) => getDownloadURL(videoRef));
        return Promise.all(urlsPromises);
      })
      .then((urls) => {
        setImageUrls(urls);
      })
      .catch((error) => {
        console.error("Error fetching videos:", error);
      });
  }, [app]); 

  const sliderSettings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  

  return (
    <div className="videogallerydiv">
      <h1 className="videogalleryheading">Video gallery</h1>
      <div className="videogallerysliderdiv">
        <Slider {...sliderSettings}>
          {imageUrls.map((videoSrc, index) => (
            <div key={index} className="videoclass">
              <video src={videoSrc} muted controls autoPlay></video>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default VideoGallery;
