import React,{useState,useEffect} from "react";
import "../styles/navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
// import Online from "./../images/online.png";
// import live from "./../images/live.png";
// import * as React from 'react';
// import { Dropdown, Menu, MenuButton, MenuItem } from "@mui/joy";
// import logoimage from '../images/logooooo-removebg-preview-removebg-preview.webp';
// import Header2 from './../components/Header2.jsx'

export default function Navbar() {
  const [marquedata,setmarquedata]=useState([]);

  useEffect(() => {
    const firestore = firebase.firestore();
    const marqueDataRef = firestore.collection("MarqueContent");
    const unsubscribe = marqueDataRef.onSnapshot((snapshot) => {
      const newMarqueData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(newMarqueData);
      setmarquedata(newMarqueData[0]);
    });
    return () => unsubscribe();
  }, []);
  return (
    <div className="navbar">
      {/* <div className="navbar-start">
        <ul className="liveDarshan">
          <li>
            <div className="online">
              <Link
                to="https://youtube.com/@ISKCONPURI?si=DPGKZg46A5BooRp6"
                style={{ color: "#65cb65", fontWeight: "bold" }}
              >
                <img class="blink_me" src={live} alt="" />
                Darshan
              >
            </div>
          </li>
          <li className="displayres responsive">
            <Dropdown>
              <MenuButton
                style={{
                  border: "none",
                  fontSize: "20px",
                  backgroundColor: "transparent",
                }}
              >
                Counselling
              </MenuButton>
              <Menu>
                <MenuItem>
                <Link
                    style={{ textDecoration: "none", color: "#636B74" }}
                    to="/"
                  >
                Home></MenuItem>
                <MenuItem>
                <Link
                    style={{ textDecoration: "none", color: "#636B74" }}
                    to="/blog"
                  >
                Blog></MenuItem>
                <MenuItem>
                <Link
                    style={{ textDecoration: "none", color: "#636B74" }}
                    to="/get-email-update"
                  >
                Support Us></MenuItem>
                <MenuItem>
                <Link
                    style={{ textDecoration: "none", color: "#636B74" }}
                    to="/get-connect-locally"
                  >
                Contact Us ></MenuItem>
              </Menu>
            </Dropdown>
          </li>
          <li className="displayres responsive">
            <Dropdown>
              <MenuButton
                style={{
                  border: "none",
                  fontSize: "20px",
                  backgroundColor: "transparent",
                }}
              >
                 <Link
                    style={{ textDecoration: "none", color: "#636B74" }}
                    to="/events"
                  >
                Event>
              </MenuButton>
              {/* <Menu>
                <MenuItem>Event 1</MenuItem>
                <MenuItem>Event 2</MenuItem>
                <MenuItem>Event 3</MenuItem>
                <MenuItem>Event 4</MenuItem>
              </Menu> */}
      {/* </Dropdown>
          </li>
          <li className="displayres responsive">
            <Dropdown>
              <MenuButton
                style={{
                  border: "none",
                  fontSize: "20px",
                  backgroundColor: "transparent",
                }}
              >
                Media
              </MenuButton>
              <Menu>
                <MenuItem>
                  <Link
                    style={{ textDecoration: "none", color: "#636B74" }}
                    to="/video-gallery"
                  >
                    Video Gallery
                  >
                </MenuItem>
                <MenuItem>
                  <Link
                    style={{ textDecoration: "none", color: "#636B74" }}
                    to="/photo-gallery"
                  >
                    Photo Gallery
                  >
                </MenuItem>
              </Menu>
            </Dropdown>
          </li>
          <li className="displayres responsive">
            <Dropdown>
              <MenuButton
                style={{
                  border: "none",
                  fontSize: "20px",
                  backgroundColor: "transparent",
                }}
              >
                Donate Now
              </MenuButton>
              <Menu>
                <MenuItem>
                  <Link
                    style={{ textDecoration: "none", color: "#636B74" }}
                    to="https://razorpay.me/@iskcon2332"
                    rel="noopener noreferrer"
                  >
                    Quick Donate
                  >
                </MenuItem>
                <MenuItem>
                  <Link
                    style={{ textDecoration: "none", color: "#636B74" }}
                    to="/donate-temple-construction-seva"
                  >
                    Temple Construction Seva
                  >
                </MenuItem>
                <MenuItem>
                  <Link
                    style={{ textDecoration: "none", color: "#636B74" }}
                    to="/donate-shastra"
                  >
                    Gita Daan Seva
                  >
                </MenuItem>
                <MenuItem>
                  <Link
                    style={{ textDecoration: "none", color: "#636B74" }}
                    to="/donate-sadhu-bhojan-seva"
                  >
                    Sadhu Bhojan Seva
                  >
                </MenuItem>
               
                <MenuItem>
                  <Link
                    style={{ textDecoration: "none", color: "#636B74" }}
                    to="/donate-food-for-life"
                  >
                    Food For Life
                  >
                </MenuItem>
                <MenuItem>
                  <Link
                    style={{ textDecoration: "none", color: "#636B74" }}
                    to="/donate-deity-seva"
                  >
                    Daily Deity Seva
                  >
                </MenuItem>
                <MenuItem>
                  {" "}
                  <Link
                    style={{ textDecoration: "none", color: "#636B74" }}
                    to="/donate-gau-seva"
                  >
                    Gau Seva
                  >
                </MenuItem>
                <MenuItem>
                  {" "}
                  <Link
                    style={{ textDecoration: "none", color: "#636B74" }}
                    to="/sponsor-feast"
                  >
                    Feast Sponsorship Seva
                  >
                </MenuItem>
                <MenuItem>
                  {" "}
                  <Link
                    style={{ textDecoration: "none", color: "#636B74" }}
                    to="/nitya-seva"
                  >
                    Nitya Sevak
                  >
                </MenuItem>
              </Menu>
            </Dropdown>
          </li>

          <li>
            <p>
              <span
                class="blink_me_youtube"
                
              >
                 <Link
                    style={{ textDecoration: "none", color: "red",fontWeight: "bolder" }}
                    to="/nitya-seva"
                  >
                Nitya Seva>
              </span>
            </p>
          </li>
        </ul>
      </div> */}
      <div className="foot">
        <h2>Food For Life Status</h2>
      </div>
      
      <div className="marqueing">
      {
          marquedata &&  <marquee>{marquedata.marqueContent}</marquee>
      }
      </div>
      <div className="navbar-end">
        <button
          className="btn"
          style={{ color: "red", fontSize: "21px", fontWeight: "bolder" }}
        >
          <FontAwesomeIcon
            className="element-to-animate"
            icon={faPhone}
            style={{ color: "lightgreen" }}
          />{" "}
          +91-6370995573
        </button>
      </div>
    </div>
  );
}
