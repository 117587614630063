import React from "react";
import AboutMain from "./AboutMain.jsx";
import History1 from "./../../images/history-iskcon.jpg";
import History2 from "./../../images/history-iskcon1.jpg";
import History3 from "./../../images/history-iskcon2.jpg";
import History4 from "./../../images/history-iskcon3.jpg";
import AboutLeft from "./AboutLeft.jsx";
const History = () => {
  return (
    <div>
      <AboutMain
        title="History Of ISKCON"
        titlep="His Divine Grace A.C. Bhaktivedanta Swami Srila Prabhupada (1896-1977), the famous chief negotiator of spiritual teachings in India, carried the movement forward and established ISKCON in New York in 1965. Let us take a look at ISKCON’s brief history!"
        headingLeft="The Beginning"
        paraLeft="In 1965, His Divine Grace Srila Prabhupada left Vrindavan behind for spreading the message of Lord Krishna in Western countries. He entered Boston with a trunk full of Lord Krishna’s books and a negligible amount of money in his pocket.

        Initially, he struggled a lot, but within a few days, people started noticing him. Some curious people joined him in his chanting and lectures, while some started to acquire serious concern about his holy mission. He lived in New York until the year 1966.
       
       He had started giving lectures on the sacred Bhagavad Gita weekly. In 1966, he established ISKCON in New York City successfully."
        imageLeft={History1}
        headingRight="Expansion of Iskcon"
        paraRight="During the period 1966-1968, more followers joined the sacred mission. As a result, he inaugurated temples in cities like Los Angeles, Seattle, San Francisco, Santa Fe, Montreal, and New Mexico.

        Rath-Yatra festival is the largest and oldest yearly festival celebrated in India. The first Rath-Yatra festival was organized in San Francisco. However, at present, this Rath-Yatra festival is celebrated in different corners of the world by the devotees of the ISKCON.
        
        Between 1969-and 1973, several temples were inaugurated in Canada, Europe, Mexico, Africa, South America, and India. A supervisory body was also established in the year 1970 to supervise society’s overall growth."
        imageRight={History2}
      />
      <AboutLeft
        headingLeft="Establishment of Bhaktivedanta Book Trust and major accomplishments of Srila Prabhupada"
        paraLeft="From 1970-to 1977, ISKCON created several major pilgrimage centers and sites at the Vrndavana and Mayapur in India. The largest temple in Mumbai was also built by the same name.

      Srila Prabhupada established BBT (Bhaktivedanta Book Trust), a publishing house, in 1972. They are one of the biggest publishers of Lord Krishna’s books in the contemporary. Srila Prabhupada converted over 40-volumes of Krishna literature from Sanskrit to English within 1966-to 1977. Books like ‘Srimad Bhagavatam’ or ‘Bhagavata Purana,’ the ’18-volumes of Lord Krishna’s incarnation’s history’, ‘pastimes and devotees,’ ‘Sri Caitanya Caritamrita,’ the ‘9-volumes of biography and teaching methods of Sri Chaitanya Mahaprabhu’ have been issued by the Bhaktivedanta Book Trust in over 50 languages."
        imageLeft={History3}
      />
      <div className="otherhistory">
        <img src={History4} alt="" />
        <p>
          Around 525 years back, Lord Krishna descended Himself on this earth to
          preach the recitation of the Holy Names and how to become a devotee in
          this Kali-yuga – by presenting Himself as a devotee of Lord. This
          devotee form was none other than the great Sri Chaitanya Mahaprabhu, a
          combined form of Srimati Radha and Sri Krishna.
        </p>
        <p>
          Later, He popularized the sacred mantra: “Hare Krishna Hare Krishna
          Krishna Krishna Hare Hare, Hare Rama Hare Rama Rama Rama Hare Hare.”
        </p>
        <p>
          Known as the Hare Krishna movement on the western side, ISKCON is
          better recognized as a monotheistic subdivision of the famous Gaudiya
          Vaishnava religious tradition. Mainly, ISKCON believes in the
          teachings of Lord Krishna.
        </p>
        <ul>
          <li>
            Bhaktivedanta Institute was created in 1973 to support the Vedas
            teachings.
          </li>
          <li>
            1974 was a crucial year for ISKCON. They started relief programs
            like food in disaster areas globally.
          </li>
          <li>
            In 1977, before Srila Prabhupada left his material body, ISKCON had
            successfully established around 108 temples, educational
            communities, centers, and over 10,000 members.
          </li>
          <li>
            The whole ‘Hare Krishna Movement’ appeared as a revolutionary change
            in the former Soviet Union in 1989.
          </li>
          <li>
            Srila Prabhupada published the monthly magazine, Back to Godhead,
            which he called the backbone of his movement. At the height of its
            circulation in the mid seventies, over a million copies per issue
            were sold.
          </li>
          <li>
            Srila Prabhupada set up farm communities to teach “simple living and
            high thinking”, emphasizing cow protection and dependence on God and
            nature.
          </li>
          <li>At present, ISKCON has more than 500 centers globally.</li>
        </ul>
      </div>
    </div>
  );
};

export default History;
