import React, { useEffect } from "react";
import QR from "./../images/qrcode3.jpg";
import "./../styles/infoIskcon.css";
import Aos from 'aos';
import "aos/dist/aos.css"
import { Link, useNavigate} from "react-router-dom";

const InfoIskcon = () => {

const navigate = useNavigate();

  const navigateToCheckout = () => {
    navigate('/checkout');
  };

useEffect(() => {
  Aos.init();
}, []);

  return (
    <>
      <div className="information">
        <div className="whatsapp" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="300">
          <p>
            Gentle Request! While doing Paytm or Bank (NEFT/ RTGS) please send
            us screen shot on our <span>Whatsapp No- +91-6370995573</span> . You
            may also call on this no for other queries.
          </p>
          <button onClick={navigateToCheckout}>DONATE ME</button>
        </div>
        <div className="vl"></div>
        <div className="banking" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="500">
          <div className="blockofcon">Donation Through Bank (NEFT/ RTGS)</div>
          <div className="inforofbank">
            <p>
              Bank Name - <b>HDFC BANK</b>
            </p>
          </div>
          <div className="inforofbank">
            <p>
              Account Name -{" "}
              <b>INTERNATIONAL SOCIETY FOR KRISHNA CONSCIOUSNESS</b>
              <button>copy</button>
            </p>
          </div>
          <div className="inforofbank">
            <p>
              Account Number – <b>50100533221349</b>
              <button>copy</button>
            </p>
          </div>
          <div className="inforofbank">
            <p>
              IFSC Code - <b>HDFC0001117</b>
              <button>copy</button>
            </p>
          </div>
          <div className="inforofbank">
            <p>
              Branch - <b>VIP ROAD Branch, Puri</b>
              <button>copy</button>
            </p>
          </div>
          <div className="orange">
            <p>
              80G AVAILABLE AS PER INCOME TAX ACT 1961 AND RULES MADE THEREUNDER
            </p>
          </div>
          <div className="income">
            <p>Income Tax Exemption (80-G) Number</p>
            <p>AAATI0017PF20219</p>
          </div>
        </div>
        <div className="vl"></div>
        <div className="qr" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="700">
          <h2 className="qrh2">PAY BY UPI</h2>
          <img src={QR} alt="" />
          <p>UPI Address: iskon.28231682@hdfcbank</p>
        </div>
      </div>
      <div className="roww">
        <p className="csr">
          For CSR enquiries :{" "}
          <Link to="mailto:official@iskconpuri.com"> official@iskconpuri.com</Link>
          <Link to="mailto:info@iskconpuri.com">
            / info@iskconpuri.com
          </Link>
        </p>
      </div>
    </>
  );
};

export default InfoIskcon;
