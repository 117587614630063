import React, { useEffect } from "react";
import "./../styles/footer.css";
import Fb from "./../images/fb.png";
import Insta from "./../images/insta.png";
import Tweet from "./../images/twee.png";
import Youtube from "./../images/youtube.png";
import Linkedin from "./../images/linkidin.png";
import logo from "../images/logooooo-removebg-preview-removebg-preview.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const Footer = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <div className="footerupper">
        <div className="footerupperinnerdiv">
        <div className="lowerring">
          <img className="footerlogo" src={logo} alt="logo" />
          <p>
            ISKCON Jagannath Puri is India's 2nd Biggets ISKCON Temple.The
            project will be spread across 22 Acres, with 2,00,000 Sq. ft. of
            construction which will be built in the Kalinga Architecture style.

          </p>
          <p>Bhaktivedanta Ashram Sipasirubuli, Puri, Odisha, 752001</p>
        </div>
        <div className="footerIcon">
          <ul>
            <li>
              <Link
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                data-aos-delay="300"
                to="https://www.facebook.com/iskconpuri123?mibextid=ZbWKwL"
                target="_blank"
                rel="noopener noreferrer"
                whileHover="hover"
                whileTap="tap"
              >
                <div>
                  <img src={Fb} alt="" />
                </div>
              </Link>
            </li>
            <li>
              <Link
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                data-aos-delay="400"
                to="https://x.com/iskconpuri12?t=NAh6IYHVBeXdIMv8tNrjBA&s=09"
                target="_blank"
                rel="noopener noreferrer"
                whileHover="hover"
                whileTap="tap"
              >
                <div>
                  <img src={Tweet} alt="" />
                </div>
              </Link>
            </li>
            <li>
              <Link
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                data-aos-delay="500"
                to="https://instagram.com/iskconpuri?igshid=YTQwZjQ0NmI0OA=="
                target="_blank"
                rel="noopener noreferrer"
                whileHover="hover"
                whileTap="tap"
              >
                <div>
                  <img src={Insta} alt="" />
                </div>
              </Link>
            </li>
            <li>
              <Link
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                data-aos-delay="600"
                to="https://www.linkedin.com/in/iskcon-puri-5395a4269?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                target="_blank"
                rel="noopener noreferrer"
                whileHover="hover"
                whileTap="tap"
              >
                <div>
                  <img src={Linkedin} alt="" />
                </div>
              </Link>
            </li>
            <li>
              <Link
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                data-aos-delay="600"
                to="https://youtube.com/@ISKCONPURI?si=DPGKZg46A5BooRp6"
                target="_blank"
                rel="noopener noreferrer"
                whileHover="hover"
                whileTap="tap"
              >
                <div>
                  <img src={Youtube} alt="" />
                </div>
              </Link>
            </li>
          </ul>
        </div>
        <div className="footerlower2">

        </div>
        </div>
        
      </div>
      <div className="footerlower">
        <Link to="/">© Copyright 2023 ISKCON PURI. All Rights Reserved</Link>
        <Link to="/terms-and-condition">Terms & Conditions</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
        {/* <Link to="/">Privacy Policy> */}
        <Link
          to="https://www.Glowtechmor.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          © Design by Glowtechmor Service PVT. LTD.
        </Link>
      </div>
    </>
  );
};

export default Footer;
